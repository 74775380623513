import React from "react";
import styles from './styles.module.css';

import ecommerceImg from '../../assets/convenience/ecommerce2.png';
import ecoEffect from '../../assets/convenience/ecoEffect.png';
import financeImg from '../../assets/convenience/Finance.png';
import financeEffect from '../../assets/convenience/financeEffect.png';
import gamingImg from '../../assets/convenience/gaming.png';
import gamingEffect from '../../assets/convenience/gamingEffect.png';
import legalImg from '../../assets/convenience/legal2.png';
import legalEffect from '../../assets/convenience/legalEffect.png';
import realestateImg from '../../assets/convenience/RealEstate.png';
import realestateEffect from '../../assets/convenience/realestateEffect.png';
import tokenImg from '../../assets/convenience/Token.png';
import tokenEffect from '../../assets/convenience/tokenEffect.png';

const ConvenienceAndProfitibilty = () => {

    return (
        <div id="ecosystem" className={styles.convenience_wrapper}>
            <h3 className={styles.Convenience_title}>Convenience and Profitability</h3>
            <div className={styles.content}>
                <div className={styles.card_col}>
                    <div>
                        <div className={styles.card_title}>Finance</div>
                        <div className={styles.card_text}>
                            Experience the dynamism, variety, and profitability of a DEX platform with Obinex. Discover crypto investment opportunities and convenient solutions for daily activities like bill payments and service hiring.
                        </div>
                    </div>
                    <div className="mt-8">
                        <img className="mx-auto" src={financeImg} alt="teamImg" />
                        <img className="absolute w-[259px] h-[276px] top-[170px] left-[60px] z-[-1]" src={financeEffect} />
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div className={styles.card_row}>
                        <div>
                            <div className={styles.card_title}>e-Commerce</div>
                            <div className={styles.card_text}>
                                Enlarge the reach of your business by offering flexibility, safety, and convenience to your customers with crypto as a payment method with the coolest UX.
                            </div>
                        </div>
                        <img className="w-full h-auto" src={ecommerceImg} alt="ticketImg" />
                        <img className="absolute w-[251px] h-[257px] right-[-70px] top-[-25px] z-[-1]" src={ecoEffect} />
                    </div>
                    <div className={styles.card_row}>
                        <div>
                            <div className={styles.card_title}>Gaming</div>
                            <div className={styles.card_text}>
                                Enjoy a revolutionary gaming experience that empowers users and content creators with unique rewards and features.
                            </div>
                        </div>
                        <img className="w-full h-auto" src={gamingImg} alt="gameImg" />
                        <img className="absolute w-[279px] h-[238px] right-[-60px] top-[-10px] z-[-1]" src={gamingEffect} />
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div className={styles.card_row}>
                        <div>
                            <div className={styles.card_title}>Legal</div>
                            <div className={styles.card_text}>
                                Be at the forefront of shaping the regulatory landscape for cryptocurrencies and DeFi with the best governance and compliance practices.
                            </div>
                        </div>
                        <img className="w-full h-auto" src={legalImg} alt="chatImg" />
                        <img className="absolute w-[225px] h-[225px] left-[155px] top-[-20px] z-[-1]" src={legalEffect} />
                    </div>
                    <div className={styles.card_row}>
                        <div>
                            <div className={styles.card_title}>Real Estate</div>
                            <div className={styles.card_text}>
                                Buy, sell, rent, or secure a loan or mortgage, conveniently and easily in the Obinex platform.
                            </div>
                        </div>
                        <img className="w-full h-auto" src={realestateImg} alt="pinImg" />
                        <img className="absolute w-[278px] h-[219px] left-[105px] top-[20px] z-[-1]" src={realestateEffect} />
                    </div>
                </div>
                <div className={styles.card_col}>
                    <div className={styles.card_title}>$OBIX Token</div>
                    <div className={styles.card_text}>
                        Access Obinex ecosystem's benefits and functionalities seamlessly with Unity Platform, powered by a single ERC-20 token for stability and fluid operations.
                    </div>
                    <div className="mt-8">
                        <img className="mx-auto" src={tokenImg} alt="tokenImg" />
                        <img className="absolute w-[312px] h-[312px] top-[125px] left-[30px] z-[-1]" src={tokenEffect} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConvenienceAndProfitibilty;