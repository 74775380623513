import React from "react";
import styles from './styles.module.css';

const WhyObinex = () => {
    return (
        <div id="aboutus" className="relative">
            <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png" alt="cloudEffect" className="absolute top-[-80px] left-[-280px]"></img>
            <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png" alt="cloudEffect" className="absolute w-[574px] h-[509px] top-[-210px] right-[-550px]"></img>
            <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png" alt="starEffectNoDeg" className="absolute w-[57px] top-[25px] left-[274px]"></img>
            {/* <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png" alt="starEffectNoDeg" className=""></img> */}
            <h3 className={styles.title}>Why Obinex?</h3>
            <div className="flex flex-wrap lg:flex-nowrap gap-[120px] lg:gap-0 w-full items-center justify-center">
                <div className={styles.secure_card}>
                    <div className="text-center pt-7 pr-[88px] pb-0 pl-[35px]">
                        <h2 className={styles.card_title}>
                            Secure
                        </h2>
                        <span className={styles.card_content_text}>
                            AI enhances transaction security by spotting suspicious patterns, boosting user trust.
                        </span>
                    </div>
                    <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/value/SecureImg.png" alt="Secure" className={styles.card_icon_img} />
                </div>
                <div className={styles.transparent_card}>
                    <div className="text-center p-[47px]">
                        <h2 className={styles.card_title}>
                            Transparent
                        </h2>
                        <span className={styles.card_content_text}>
                            Have the opportunity to pay less fees with our new transaction volume fee reduction system.
                        </span>
                    </div>
                    <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/value/TransparentImg.png" alt="Transparent" className={styles.card_icon_img} />
                </div>
                <div className={styles.tailored_card}>
                    <div className="text-center pt-7 pr-[33px] pb-0 pl-[55px]">
                        <h2 className={styles.card_title}>
                            Tailored
                        </h2>
                        <span className={styles.card_content_text}>
                            Are you experiencing any issue?Our dedicated expert support team is here to prioritize your peace of mind.
                        </span>
                    </div>
                    <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/value/TailoredImg.png" alt="Tailored" className={styles.card_icon_img} />
                </div>
            </div>
        </div>
    )
}

export default WhyObinex;