import React from "react";
import './style.css';
import rightArrow from "../../assets/Roadmap/rightArrow.png";
import leftArrow from "../../assets/Roadmap/leftArrow.png";
import downArrow from "../../assets/Roadmap/downArrow.png";
import cube2 from "../../assets/Roadmap/cube2.png";
import referral2 from "../../assets/Roadmap/referral2.png";
import wix2 from "../../assets/Roadmap/wix2.png";
import blockchain2 from "../../assets/Roadmap/blockchain2.png";

const Roadmapfunction = () => {
    return (
        <div id="roadmap" className="RoadmapContent">
            <div className="Roadmap">
                <h3>Roadmap</h3>
                <div className="content">
                    <div className="top-bar">
                        <div className="top-bar-first-part">
                            2024
                        </div>
                        <div className="bar-card">
                            <img src={rightArrow} alt="rightArrow" className="rightArrowimg" />
                            <div className="bar-card-title">Q1 2024</div>
                            <div className="card">
                                <img src={referral2} alt="referral2" />
                                <span className="card-text">Referral Platform Launch</span>
                            </div>
                            <div className="card">
                                <img src={cube2} alt="cube2" />
                                <span className="card-text">Private Equity Rounds Starts</span>
                            </div>
                            <div className="card">
                                <img src={wix2} alt="wix2" />
                                <span className="card-text">Second Strategic Partners Round</span>
                            </div>
                        </div>
                        <div className="bar-card">
                            <img src={rightArrow} alt="rightArrow" className="rightArrowimg" />
                            <div className="bar-card-title">Q2 2024</div>
                            <div className="card">
                                <img src={referral2} alt="referral2" />
                                <span className="card-text">Liquidity Launch</span>
                            </div>
                            <div className="card">
                                <img src={blockchain2} alt="cube2" />
                                <span className="card-text">Vesting Starts</span>
                            </div>
                            <div className="card">
                                <img src={wix2} alt="wix2" />
                                <span className="card-text">CEX Development Starts</span>
                            </div>
                        </div>
                        <div className="bar-card">
                            <img src={rightArrow} alt="rightArrow" className="rightArrowimgQ3" />
                            <img src={downArrow} alt="downArrow" className="top-bar-downArrow" />
                            <div className="bar-card-title">Q3 2024</div>
                            <div className="card">
                                <img src={referral2} alt="referral2" />
                                <span className="card-text">Regulatory Process Starts</span>
                            </div>
                            <div className="card">
                                <img src={blockchain2} alt="cube2" />
                                <span className="card-text">South America & Africa Markets</span>
                            </div>
                            <div className="card">
                                <img src={wix2} alt="wix2" />
                                <span className="card-text">Blockchain Development Starts</span>
                            </div>
                        </div>
                        <div className="bar-card">
                            <img src={rightArrow} alt="rightArrow" className="rightArrowimgQ4" />
                            <div className="bar-card-title">Q4 2024</div>
                            <div className="card">
                                <img src={referral2} alt="referral2" />
                                <span className="card-text">Trading Launches on BSC</span>
                            </div>
                            <div className="card">
                                <img src={blockchain2} alt="cube2" />
                                <span className="card-text">Staking Pools</span>
                            </div>
                            <div className="card">
                                <img src={wix2} alt="wix2" />
                                <span className="card-text">Mobile App Development</span>
                            </div>
                        </div>
                    </div>
                    <div className="down-bar">
                        <div className="bar-card" style={{ zIndex: "5" }}>
                            <img src={leftArrow} alt="leftArrow" className="leftArrowQ4" />
                            <div className="bar-card-title">Q4 2025</div>
                            <div className="card">
                                <img src={referral2} alt="referral2" />
                                <span className="card-text">Blockchain Mainnet Launch</span>
                            </div>
                            <div className="card">
                                <img src={blockchain2} alt="cube2" />
                                <span className="card-text">Predictive AI implementation</span>
                            </div>
                            <div className="card">
                                <img src={wix2} alt="wix2" />
                                <span className="card-text">e-Commerce Platform Ddevelopment</span>
                            </div>
                        </div>
                        <div className="bar-card" style={{ zIndex: "4" }}>
                            <img src={leftArrow} alt="leftArrow" className="leftArrowQ3" />
                            <div className="bar-card-title">Q3 2025</div>
                            <div className="card">
                                <img src={referral2} alt="referral2" />
                                <span className="card-text">Blockchain Testnet Launch Start</span>
                            </div>
                            <div className="card">
                                <img src={wix2} alt="wix2" />
                                <span className="card-text">Bridges Development Start</span>
                            </div>
                        </div>
                        <div className="bar-card" style={{ zIndex: "3" }}>
                            <img src={leftArrow} alt="leftArrow" className="leftArrow" />
                            <img src={downArrow} alt="downArrow" className="down-bar-downArrow" />
                            <div className="bar-card-title">Q2 2025</div>
                            <div className="card">
                                <img src={referral2} alt="referral2" />
                                <span className="card-text">Custody Service Development</span>
                            </div>
                            <div className="card">
                                <img src={blockchain2} alt="cube2" />
                                <span className="card-text">New Market Expansion</span>
                            </div>
                        </div>
                        <div className="bar-card" style={{ zIndex: "2" }}>
                            <img src={leftArrow} alt="leftArrow" className="leftArrow" />
                            <div className="bar-card-title">Q1 2025</div>
                            <div className="card">
                                <img src={referral2} alt="referral2" />
                                <span className="card-text">Improved P2P Service Launch</span>
                            </div>
                            <div className="card">
                                <img src={blockchain2} alt="cube2" />
                                <span className="card-text">DEX Implementation</span>
                            </div>
                            <div className="card">
                                <img src={wix2} alt="wix2" />
                                <span className="card-text">Mobile App Launch</span>
                            </div>
                        </div>
                        <div className="down-bar-first-part">
                            <img src={downArrow} alt="downArrow" className="downArrow" />
                            2025
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmapfunction;