import React from "react";
import { clx } from "../../helpers/clx";
import styles from "./styles.module.css";

import tokenomicImg from "../../assets/tokenomic.png";
import tokenomicEffect from "../../assets/tokenomicEffect.png";

const ObixToken = () => {
  return (
    <div id="tokenomics">
      <div className="relative">
        <h3 className={styles.tokenomics_title}>
          $OBIX Token
        </h3>
        <div className={styles.tokenomics_description}>
          Our tokenomics relies on a hybrid balancing
          technology that allows the token offer to be
          regulated, ensuring that the token supply adapts
          to the project's development stage and specific
          market conditions, causing the token price to be
          remunerative for investors while remaining
          attractive to platform users: the supply increment
          is balanced by token burns on each transaction.
        </div>
      </div>
      <div className={styles.tokenomics_container}>
        <img
          src={tokenomicEffect}
          className="hidden lg:flex scale-[2] z-[-1]"
        />
        <div
          className={clx(
            styles.token_item_wrapper,
            styles.liquidity_wrapper
          )}
          style={{ right: "75%", top: "3%" }}
        >
          <div
            className={clx(
              styles.token_progress,
              styles.liquidity_progress
            )}
          >
            15.14%
          </div>
          <h3
            className={clx(
              styles.token_name,
              styles.liquidity_name
            )}
          >
            Liquidity
          </h3>
          <div className={styles.token_counts}>
            <span className="text-black/70">tokens</span>
            <span className="text-black/50">
              11.894,127
            </span>
          </div>
        </div>
        <div
          className={clx(
            styles.token_item_wrapper,
            styles.private_wrapper
          )}
          style={{ left: "75%", top: "1.7%" }}
        >
          <div
            className={clx(
              styles.token_progress,
              styles.private_progress
            )}
          >
            14.63%
          </div>
          <h3
            className={clx(
              styles.token_name,
              styles.private_name
            )}
          >
            Private Sale
          </h3>
          <div className={styles.token_counts}>
            <span className="text-black/70">tokens</span>
            <span className="text-black/50">
              11.487,965
            </span>
          </div>
        </div>
        <div
          className={clx(
            styles.token_item_wrapper,
            styles.marketing_wrapper
          )}
          style={{ right: "88.3%", top: "21.3%" }}
        >
          <div
            className={clx(
              styles.token_progress,
              styles.marketing_progress
            )}
          >
            9.18%
          </div>
          <h3
            className={clx(
              styles.token_name,
              styles.marketing_name
            )}
          >
            Marketing
          </h3>
          <div className={styles.token_counts}>
            <span className="text-black/70">tokens</span>
            <span className="text-black/50">7.213,246</span>
          </div>
        </div>
        <div
          className={clx(
            styles.token_item_wrapper,
            styles.public_sale1_wrapper
          )}
          style={{ left: "91%", top: "21.3%" }}
        >
          <div
            className={clx(
              styles.token_progress,
              styles.public_sale1_progress
            )}
          >
            21.24%
          </div>
          <h3
            className={clx(
              styles.token_name,
              styles.public_sale1_name
            )}
          >
            Public Sale 1
          </h3>
          <div className={styles.token_counts}>
            <span className="text-black/70">tokens</span>
            <span className="text-black/50">
              16.684,902
            </span>
          </div>
        </div>
        <div
          className={clx(
            styles.token_item_wrapper,
            styles.airdrops_wrapper
          )}
          style={{ right: "91%", top: "67%" }}
        >
          <div
            className={clx(
              styles.token_progress,
              styles.airdrops_progress
            )}
          >
            1.25%
          </div>
          <h3
            className={clx(
              styles.token_name,
              styles.airdrops_name
            )}
          >
            Airdrops
          </h3>
          <div className={styles.token_counts}>
            <span className="text-black/70">tokens</span>
            <span className="text-black/50">980,187</span>
          </div>
        </div>
        <div
          className={clx(
            styles.token_item_wrapper,
            styles.public_sale2_wrapper
          )}
          style={{ left: "91%", top: "67%" }}
        >
          <div
            className={clx(
              styles.token_progress,
              styles.public_sale2_progress
            )}
          >
            33.08%
          </div>
          <h3
            className={clx(
              styles.token_name,
              styles.public_sale2_name
            )}
          >
            Public Sale 2
          </h3>
          <div className={styles.token_counts}>
            <span className="text-black/70">tokens</span>
            <span className="text-black/50">
              25.984,683
            </span>
          </div>
        </div>
        <div
          className={clx(
            styles.token_item_wrapper,
            styles.team_wrapper
          )}
          style={{ right: "67%", top: "91%" }}
        >
          <div
            className={clx(
              styles.token_progress,
              styles.team_progress
            )}
          >
            3.83%
          </div>
          <h3
            className={clx(
              styles.token_name,
              styles.team_name
            )}
          >
            Team
          </h3>
          <div className={styles.token_counts}>
            <span className="text-black/70">tokens</span>
            <span className="text-black/50">3.010,659</span>
          </div>
        </div>
        <div
          className={clx(
            styles.token_item_wrapper,
            styles.advisors_wrapper
          )}
          style={{ left: "67%", top: "91%" }}
        >
          <div
            className={clx(
              styles.token_progress,
              styles.advisors_progress
            )}
          >
            1.63%
          </div>
          <h3
            className={clx(
              styles.token_name,
              styles.advisors_name
            )}
          >
            Advisors &amp; Partners
          </h3>
          <div className={styles.token_counts}>
            <span className="text-black/70">tokens</span>
            <span className="text-black/50">1.283,443</span>
          </div>
        </div>
        <img
          src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/tokenomix/VectorLeft.png"
          alt="Circleleft"
          className="hidden lg:block absolute left-[-3%] top-[41%]"
        />
        <img
          src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/tokenomix/VectorRight.png"
          alt="Circleright"
          className="hidden lg:block absolute right-[-3%] top-[41%]"
        />
      </div>
    </div>
  );
};

export default ObixToken;
