import React from "react";
import styles from './styles.module.css';

const Team = () => {
  return (
      <div id="team" className="flex flex-col items-center relative pb-[177px]">
        <img
          src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
          alt="cloudImg"
          className="absolute w-[356px] h-auto top-0 left-0 z-[-1] translate-x-[-45%] translate-y-[-40%]"
        />
        <h3 className={styles.team_title}>
          The Team Behind Obinex
        </h3>
        <div className={styles.team_cards_wrapper}>
          <div>
            <img
              alt="Alejandro Grande"
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/alejandro.png"
              className={styles.team_card_img}
            />
            <div className={styles.team_card_text}>
              <div>
                <div className={styles.team_member_position}>
                  CEO | Founder
                </div>
                <div className={styles.team_member_name}>
                  Alejandro Grande
                </div>
              </div>
              <div className="mt-[6px]">
                <a
                  href="https://www.linkedin.com/in/alejandro-obinex/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://obinex.com/static/media/linkedin.406b1fe5586ce14ea65e01fe03edc36b.svg"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
          <div>
            <img
              alt="Miguel Castaño"
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/miguel.png"
              className={styles.team_card_img}
            />
            <div className={styles.team_card_text}>
              <div>
                <div className={styles.team_member_position}>
                  CIO | Co-Founder
                </div>
                <div className={styles.team_member_name}>
                  Miguel Castaño
                </div>
              </div>
              <div className="mt-[6px]">
                <a
                  href="https://www.linkedin.com/in/mch310199/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://obinex.com/static/media/linkedin.406b1fe5586ce14ea65e01fe03edc36b.svg"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
          <div>
            <img
              alt="Jose Antonio López"
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/jose.png"
              className={styles.team_card_img}
            />
            <div className={styles.team_card_text}>
              <div>
                <div className={styles.team_member_position}>CLO</div>
                <div  className={styles.team_member_name}>
                  Jose Antonio López
                </div>
              </div>
              <div className="mt-[6px]">
                <a
                  href="https://www.linkedin.com/in/jose-antonio-lopez-30678a9/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://obinex.com/static/media/linkedin.406b1fe5586ce14ea65e01fe03edc36b.svg"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-[60px]">
          <button className={styles.more_button}>
            Meet More Key People
          </button>
        </div>
        <img
          src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
          alt="stareffect"
          className="absolute top-[445px] left-0"
        />
        <img
          src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
          alt="stareffect"
          className="absolute w-[40px] top-[130px] right-0"
        />
        <img
          src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
          alt="stareffect"
          className="absolute top-5 left-[260px] z-[-1]"
        />
        <div className="sc-fYrVWQ hTtItI"></div>
        <div className="sc-GkLId eCSZx"></div>
      </div>
  );
};

export default Team;
