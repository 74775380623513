import "./App.css";
import ConvenienceAndProfitibilty from "./components/ConvenienceAndProfitability/ConvenienceAndProfiltibilty";
import Menu from "./components/Menu/Menu";
import Welcome from "./components/Welcome/Welcome";
import WhyObinex from "./components/WhyObinex/WhyObinex";
import RoadMap from "./components/Roadmap/Roadmap";
import ObixToken from "./components/ObixToken/ObixToken";
import Sponsor from "./components/Sponsor/Sponsor";
import Subscribe from "./components/Subscribe/Subscribe";
import Team from "./components/Team/Team";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <div className="background"></div>
      <div className="background-color"></div>
      <div className="container">
        <Menu />
        <Welcome />
        <ConvenienceAndProfitibilty />
        <WhyObinex />
        <RoadMap />
        <ObixToken />
        <Sponsor />
        <Subscribe />
        <Team />
        <Footer />
      </div>
    </div>
  );
}

export default App;
