import React from 'react';
import styles from './styles.module.css'

const Sponsor = () => {
    return (
        <div>
            <div className={styles.divide_line}></div>
            <div className={styles.sponsor_wrapper}>
                <a href="https://www.ois.dev/" target="_blank" rel="noreferrer">
                    <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/OIS.png" alt="ois" className="sc-lkDHyp edlNSV" />
                </a>
                <a href="https://neocoder.com/" target="_blank" rel="noreferrer">
                    <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/Neocoder.png" alt="neo" className="sc-lkDHyp edlNSV" />
                </a>
                <a href="https://www.linkedin.com/company/erc20-academy-consulting-investment/" target="_blank" rel="noreferrer">
                    <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/ERC20.png" alt="erc20" className="sc-lkDHyp edlNSV" />
                </a>
                <a href="https://mixedrarity.com/" target="_blank" rel="noreferrer">
                    <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/mixed_rarity.png" alt="rarity" className="sc-lkDHyp edlNSV" />
                </a>
                <a href="https://wayops.eu/" target="_blank" rel="noreferrer">
                    <img src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/wayops.png" alt="wayops" className="sc-lkDHyp edlNSV" />
                </a>
            </div>
        </div>
    )
}

export default Sponsor;