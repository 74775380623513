import React from "react";
import styles from "./styles.module.css";

const Subscribe = () => {
  return (
    <div className={styles.subscribe_container}>
      <div className={styles.subscribe_left}>
        <h2 className={styles.newsletter}>Newsletter</h2>
        <div className="text-center w-full">
          <input
            placeholder="First Name"
            className={styles.newsletter_input}
          />
          <input
            placeholder="Last Name"
            className={styles.newsletter_input}
          />
          <input
            placeholder="Email"
            className={styles.newsletter_input}
          />
          <button className={styles.newsletter_button}>
            Subscribe
          </button>
          <div className="flex justify-center mt-[7px]">
            <label className={styles.newsletter_confirm_label}>
              I agree with the storage and handling of any
              data by the website.
              <p className={styles.privacy_policy}>
                Privacy Policy
              </p>
              <input type="checkbox" />
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div className="flex justify-center md:block">
        <img
          src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/emailImg.png"
          alt="emailImg"
        />
      </div>
      <img
        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
        alt="starEffect"
        className="absolute left-[-60px] top-[72px]"
      />
      <img
        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
        alt="starEffect"
        className="absolute bottom-[40px] right-[390px]"
      />
    </div>
  );
};

export default Subscribe;
