import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import styles from "./styles.module.css";
import { ReactComponent as Logo } from "../../assets/logo.svg";

const Menu = () => {
  const [focusedItem, setFocusedItem] = useState("welcome");
  const [showDropDown, setShowDropDown] = useState(false);

  const handleFocusChange = (itemName: string) => {
    setFocusedItem(itemName);
  };
  return (
    <div className={styles.top_menu_container}>
      <div className={styles.top_menu_bar}>
        <ScrollLink
          to="welcome"
          spy={true}
          duration={"500"}
          smooth={true}
        >
          <Logo className="cursor-pointer" />
        </ScrollLink>
        <div className={styles.top_menu}>
          {[
            "ecosystem",
            "aboutus",
            "tokenomics",
            "roadmap",
            "team",
          ].map((item, index) => (
            <ScrollLink
              key={item}
              to={item}
              spy={true}
              duration={"500"}
              smooth={true}
              onClick={() => handleFocusChange(item)}
            >
              <div
                className={`${styles.menu_item} ${
                  focusedItem === item ? styles.focused : ""
                }`}
                tabIndex={index}
              >
                {item.charAt(0).toUpperCase() +
                  item.slice(1)}
              </div>
            </ScrollLink>
          ))}
          <div
            className={`${styles.menu_item} ${
              focusedItem === "litepaper"
                ? styles.focused
                : ""
            }`}
            onClick={() => handleFocusChange("litepaper")}
            tabIndex={5}
          >
            Litepaper
          </div>
        </div>
        <div className={styles.menu_btn_grp}>
          <button className={styles.menu_wallet_btn}>
            Connect Wallet
          </button>
          <div className={styles.burger_icon}>
            <div
              onClick={() => setShowDropDown(!showDropDown)}
            >
              <img
                src="https://obinex.com/static/media/hamburger.8bbbb9a87aa03e3a480a7b4480180566.svg"
                alt="menu"
              />
            </div>
            <div
              className={styles.dropdown}
              style={{
                display: showDropDown ? "flex" : "none",
              }}
            >
              {[
                "ecosystem",
                "aboutus",
                "tokenomics",
                "roadmap",
                "team",
              ].map((item, index) => (
                <ScrollLink
                  key={item}
                  to={item}
                  spy={true}
                  duration={"500"}
                  smooth={true}
                  onClick={() => handleFocusChange(item)}
                >
                  <div className={styles.dropdown_item}>
                    {item.charAt(0).toUpperCase() +
                      item.slice(1)}
                  </div>
                </ScrollLink>
              ))}
              <div className={styles.dropdown_item}>
                Litepaper
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
