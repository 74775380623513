import React from "react";
import styles from "./styles.module.css";

import starImg from "../../assets/star.png";
import cloudImg from "../../assets/cloud.png";

const Welcome = () => {
  return (
    <div id="welcome" className={styles.welcome_container}>
      <div className={styles.welcome_content}>
        <div className={styles.effect_1}></div>
        <div className={styles.effect_2}></div>
        <div className={styles.effect_3}></div>
        <div className={styles.effect_4}></div>
        <div className={styles.effect_5}></div>
        <div className={styles.welcome_text_style_1}>
          Bringing the
        </div>
        <div className={styles.welcome_text_style_2}>
          Future
        </div>
        <div className={styles.welcome_text_style_1}>
          to now!
        </div>
        <div className={styles.welcome_text_content}>
          We are going to be the largest, most trusted and
          stable ecosystem in the world
        </div>
        <div className={styles.external_icons}>
          <a
            href="https://twitter.com/obinexofficial"
            target="_blank"
            className={styles.social_icon}
          >
            <img
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/twitter.svg"
              alt="twitter"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/obinex/"
            target="_blank"
            className={styles.social_icon}
          >
            <img
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/linkedin.svg"
              alt="linkedin"
            />
          </a>
          <a
            href="https://t.me/ObinexEnglish"
            target="_blank"
            className={styles.social_icon}
          >
            <img
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/telegram.svg"
              alt="telegram"
            />
          </a>
          <a
            href="https://discord.gg/obix"
            target="_blank"
            className={styles.social_icon}
          >
            <img
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/discord.svg"
              alt="discord"
            />
          </a>
          <a
            href="https://medium.com/@obinex"
            target="_blank"
            className={styles.social_icon}
          >
            <img
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/medium.svg"
              alt="medium"
            />
          </a>
          <a
            href="https://www.instagram.com/obinexofficial/ "
            target="_blank"
            className={styles.social_icon}
          >
            <img
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/instagram.svg"
              alt="instagram"
            />
          </a>
        </div>
      </div>
      <div className={styles.welcome_img}>
        <img className={styles.star_first} src={starImg} />
        <img className={styles.star_second} src={starImg} />
        <img
          className={styles.cloud_first}
          src={cloudImg}
        />
        <img
          className={styles.cloud_second}
          src={cloudImg}
        />
        <img
          className={styles.cloud_third}
          src={cloudImg}
        />
      </div>
    </div>
  );
};

export default Welcome;
