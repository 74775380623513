import React from "react";
import styles from "./styles.module.css";

const Footer = () => {
  return (
    <div>
      <div className={styles.footer_division}></div>
      <div className={styles.footer_section}>
        <div className={styles.social_link_wrapper}>
          <div className={styles.company_log}>
            <img
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/b78731b4f1a5c7539fb4ab635beb0c2d161fb702/componentImg/footerlogo/logo.svg"
              className="w-[44px] h-[43px]"
            />
            <img
              src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/footerlogo/logoLetter.png"
              className="w-[133px] h-[53px]"
            />
          </div>
          <div className="flex flex-col gap-[5px]">
            <div className={styles.social_links}>
              <a
                href="https://twitter.com/obinexofficial"
                target="_blank"
                className={styles.link}
              >
                <img
                  src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/twitter.svg"
                  alt="twitter"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/obinex/"
                target="_blank"
                className={styles.link}
              >
                <img
                  src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/linkedin.svg"
                  alt="linkedin"
                />
              </a>
              <a
                href="https://t.me/ObinexEnglish"
                target="_blank"
                className={styles.link}
              >
                <img
                  src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/telegram.svg"
                  alt="telegram"
                />
              </a>
              <a
                href="https://discord.gg/obix"
                target="_blank"
                className={styles.link}
              >
                <img
                  src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/discord.svg"
                  alt="discord"
                />
              </a>
              <a
                href="https://medium.com/@obinex"
                target="_blank"
                className={styles.link}
              >
                <img
                  src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/medium.svg"
                  alt="medium"
                />
              </a>
              <a
                href="https://www.instagram.com/obinexofficial/ "
                target="_blank"
                className={styles.link}
              >
                <img
                  src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/instagram.svg"
                  alt="instagram"
                />
              </a>
            </div>
            <span>Join the future of finance</span>
          </div>
        </div>
        <div className={styles.company_info}>
          <div className="mt-0">
            <span className={styles.section_title}>
              Company
            </span>
            <div className={styles.section}>
              <span className={styles.section_item}>
                About Us
              </span>
              <span className={styles.section_item}>
                Our Services
              </span>
              <span className={styles.section_item}>
                Blog
              </span>
              <span className={styles.section_item}>
                Careers
              </span>
              <span className={styles.section_item}>
                Ambassadors Program
              </span>
              <span className={styles.section_item}>
                Contact the Team
              </span>
              <span className={styles.section_item}>
                Request a Feature
              </span>
            </div>
          </div>
          <div className="mt-0">
            <span className={styles.section_title}>
              Legal
            </span>
            <div className={styles.section}>
              <span className={styles.section_item}>
                Privacy Policy
              </span>
              <span className={styles.section_item}>
                Terms of Use
              </span>
              <span className={styles.section_item}>
                Cookies Policy
              </span>
              <span className={styles.section_item}>
                Disclaimer
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
